<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">已归档结算管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="结算单编号"  class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">结算单编号:</span>
              <el-input
                v-model="retrievalDatas.billNo"
                type="text"
                size="small"
                placeholder="请输入结算单编号"
                clearable
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="retrievalDatas.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="是否开票" class="searchboxItem ci-full">
              <span class="itemLabel">是否开票:</span>
              <el-select
                size="small"
                v-model="retrievalDatas.isInvoice"
                placeholder="请选择是否开票"
                clearable
                ><el-option
                  v-for="item in invoiceOrNot"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
           
          </div>
          <div class="searchbox" style="margin-top: 10px">
              
             <div title="收(付)款日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">收(付)款日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalDatas.payeeDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="财务复核日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem"
                >财务复核日期:</span
              >
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalDatas.financialReviewDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="创建日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">创建日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalDatas.createDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="结算单收回日期" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:10rem">结算单收回日期:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="retrievalDatas.billRecycleDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
             <div title="结算单类型" class="searchboxItem ci-full">
              <span class="itemLabel">结算单类型:</span>
              <el-select
                size="small"
                v-model="retrievalDatas.billType"
                placeholder="请选择结算单类型"
                clearable
                ><el-option
                  v-for="item in billTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df" style="padding:0 15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="结算单编号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
                min-width="150px"
              />
             
              <el-table-column
                label="结算单类型"
                align="left"
                show-overflow-tooltip
                prop="billType"
                min-width="150px"
              >
               <template slot-scope="scope">
                {{scope.row.billType == '10' ? '收款结算单':scope.row.billType == '20' ? '收款结算单' :'代理商结算单'}}
              </template>
              </el-table-column>
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                width="260px"
              >
              </el-table-column>
              <el-table-column
                label="收(付)款金额"
                align="left"
                show-overflow-tooltip
                prop="payerAmount"
                min-width="120px"
              />
              <el-table-column
                label="结算人数"
                align="left"
                show-overflow-tooltip
                prop="settlementUserCount"
                min-width="80px"
              >
              </el-table-column>
              <el-table-column
                label="创建日期"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="财务复核日期"
                align="left"
                show-overflow-tooltip
                prop="financialReviewDate"
                min-width="100px"
              >
               <template slot-scope="scope">
                  {{ scope.row.financialReviewDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="收(付)款日期"
                align="left"
                show-overflow-tooltip
                prop="payeeDate"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.payeeDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="结算单收回日期"
                align="left"
                show-overflow-tooltip
                prop="billRecycleDate"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.billRecycleDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="是否开票"
                align="left"
                show-overflow-tooltip
                prop="isInvoice"
                min-width="120px"
              >
                <template slot-scope="scope">
                  {{ scope.row.isInvoice ?'是':'否' }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 5px"
                    @click="toVoid(scope.row.billId)"
                    >作废</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
        <!-- 作废 - loading -->
    <el-dialog
      title="作废结算单"
      :visible.sync="toVoidLoding"
      width="30%"
      class="toVoidLoding"
    >
      <p class="p1">确定作废该结算单吗？</p>
      <p class="p2">结算单作废后，可重新生成。</p>
      <p class="p2">原来的结算单号作废，重新生成新的结算单号。</p>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidLoding = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          class="bgc-bv"
          round
          @click="toVoidDetermine"
          >确 定</el-button
        >
      </span>
    </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "evaluate/contractManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面检索数据
      retrievalDatas: {
        billNo: "",
        compId: "",
        payeeDate:'',
        isInvoice:'',
financialReviewDate:'',
createDate:'',
billRecycleDate:'',
billType:'',
      },
      // 机构名称 - 下拉数据
      companyList: [],
      // 是否开票 - 下拉数据
      invoiceOrNot: [
        {
          value: false,
          label: "否",
        },
        {
          value: true,
          label: "是",
        },
      ],
      billTypeList: [
        {
          value: '10',
          label: "收款结算单",
        },
        {
          value: '20',
          label: "付款结算单",
        },
        {
          value: '30',
          label: "代理商结算单",
        },
      ],
      // 结算单状态 - 下拉数据
      statementList: [],
      toVoidLoding:false,
    };
  },
  watch: {},
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        isInvoice:this.retrievalDatas.isInvoice
      };
      if (this.retrievalDatas.billNo) {
        params.billNo = this.retrievalDatas.billNo;
      }
      if (this.retrievalDatas.billType) {
        params.billType = this.retrievalDatas.billType;
      }
      if (this.retrievalDatas.compId) {
        params.compId = this.retrievalDatas.compId;
      }
      if (this.retrievalDatas.payeeDate) {
        params.payeeDateStart = this.retrievalDatas.payeeDate[0];
        params.payeeDateEnd = this.retrievalDatas.payeeDate[1];
      }
      if (this.retrievalDatas.financialReviewDate) {
        params.financialReviewDateStart = this.retrievalDatas.financialReviewDate[0];
        params.financialReviewDateEnd = this.retrievalDatas.financialReviewDate[1];
      }
      if (this.retrievalDatas.createDate) {
        params.createDateStart = this.retrievalDatas.createDate[0];
        params.createDateEnd = this.retrievalDatas.createDate[1];
      }
      if (this.retrievalDatas.billRecycleDate) {
        params.billRecycleDateStart = this.retrievalDatas.billRecycleDate[0];
        params.billRecycleDateEnd = this.retrievalDatas.billRecycleDate[1];
      }
      this.doFetch({
        url: "/biz/new/bill/archived/page",
        params,
        pageNum,
      });
    },
   // 作废
    toVoid(billId) {
      this.toVoidLoding = true;
      this.billId = billId;
    },
    // 作废 - 确定
    toVoidDetermine() {
      this.$post("/biz/new/bill/archived/invalid", { billId: this.billId })
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "作废成功",
              type: "success",
            });
            this.toVoidLoding = false;
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 +4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    // 监听缓存后调用接口
    $route: {
      handler: function(route) {
        if (route.query.refresh == true) {
          this.getData();
        }
        // this.activeName = route.params.refName;
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.toVoidLoding /deep/ .el-dialog__footer {
  text-align: center;
}
.toVoidLoding /deep/ .el-dialog__footer .el-button {
  width: 80px;
}
.toVoidLoding /deep/ .el-dialog__body .p1 {
  text-align: center;
  font-size: 17px;
  margin: 30px 0 10px 0;
}
.toVoidLoding /deep/ .el-dialog__body .p2 {
  text-align: center;
}
</style>
